import { BsRecord, BsStop } from 'react-icons/bs'
import { usePlayerStore } from '@/src/lib/playerStore'
import { FC, useEffect, useState } from 'react'
import { HBox } from 'intuitive-flexbox'
import { useTracks } from '@/src/context/audio-provider'
import { useBpmStore } from '@/src/lib/bpmStore'
import { useKeyStore } from '@/src/lib/keyStore'
import { PlayIcon, PauseIcon } from './play-pause'
import { useSearchParams } from 'next/navigation'
import Spinner from './Spinner'

const Transport = ({
  playAll,
  pauseAll,
  startRecording,
  stopRecording,
  isRecording,
  PlusButton
}: {
  playAll: () => void
  pauseAll: () => void
  startRecording: () => void
  stopRecording: () => void
  isRecording: boolean
  PlusButton: FC
}) => {
  const isPlaying = usePlayerStore((state) => state.isPlaying)
  const { tracks, allTracksLoaded, updateKey, updateBpm } = useTracks()
  const [readyForPlayback, setReadyForPlayback] = useState(allTracksLoaded)
  const bpm = useBpmStore((state) => state.bpm)
  const currentBpm = useBpmStore((state) => state.currentBpm)
  const key = useKeyStore((state) => state.key)
  const currentKey = useKeyStore((state) => state.currentKey)

  const searchParams = useSearchParams()
  const isEmbedded = searchParams.get('embedded') !== null

  // This is the place to modify tracks after they have been loaded
  useEffect(() => {
    setReadyForPlayback(tracks.length > 0 && allTracksLoaded)
    // set key and bpm after tracks have been loaded
    if (currentBpm && currentBpm !== bpm) updateBpm(currentBpm)
    if (currentKey && key && currentKey !== key) updateKey(key, currentKey)
  }, [tracks, allTracksLoaded])

  return (
    <HBox className="w-full items-center justify-around gap-2 text-primary">
      {readyForPlayback ? (
        <button
          title={!readyForPlayback ? 'Not ready for playback' : isPlaying ? 'Pause' : 'Play'}
          className="rounded-full p-1"
          disabled={!readyForPlayback}
          onClick={isPlaying ? pauseAll : playAll}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
      ) : (
        <Spinner size={24} className="text-white" />
      )}
      {PlusButton({})} {/* Quick fix to avoid too many re-renders, causing backwards input */}
      {/* recording button */}
      <button
        title={!readyForPlayback ? 'Not ready for recording' : isRecording ? 'Stop Recording ' : 'Start Recording'}
        className="rounded-full p-1"
        onClick={() => {
          if (isEmbedded) {
            // @ts-ignore
            window.webkit.messageHandlers.alertHandler.postMessage('Recording on iOS is coming soon')
          } else {
            if (isRecording) {
              stopRecording()
            } else {
              startRecording()
            }
          }
        }}
      >
        {isRecording ? (
          <BsStop size={30} className={readyForPlayback ? 'text-white' : 'text-gray-500'} />
        ) : (
          <BsRecord size={30} className={readyForPlayback ? 'text-white' : 'text-gray-500'} />
        )}
      </button>
    </HBox>
  )
}

export default Transport

// These are copied from GrPlay/GrPause and modified from react-icons to get around a hard to reproduce
// rendering issue in WebKit only.

export const PlayIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" style={{ transform: 'translateZ(0)' }}>
    <path stroke="white" strokeWidth="2" fill="transparent" d="M8 5v14l11-7z" />
  </svg>
)

export const PauseIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="20" style={{ transform: 'translateZ(0)' }}>
    <path fill="transparent" stroke="white" strokeWidth="2" d="M3 21h6V3H3v18zm12 0h6V3h-6v18z" />
  </svg>
)
